import { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Context from "store/context";
import { ProfileType } from "store/interfaces";

const useNavigator = () => {
  const { globalState, globalDispatch } = useContext(Context);

  const history = useHistory();
  const location = useLocation();

  // const switchProfile = useCallback(
  //   (profile: ProfileType) => {
  //     globalDispatch({
  //       type: SWITCH_PROFILE,
  //       profile: profile.toLowerCase(),
  //     });
  //   },
  //   [globalDispatch]
  // );

  useEffect(() => {
    if (globalState.profile === ProfileType.OADC) {
      if (!location.pathname.startsWith("/oadc")) {
        return history.replace("/oadc");
      }
    } else if (globalState.profile === ProfileType.OAMS) {
      if (!location.pathname.startsWith("/oams")) {
        return history.replace("/oams");
      }
    } else if (globalState.profile === ProfileType.CONNECTIVITY) {
      if (
        location.pathname.startsWith("/oams") ||
        location.pathname.startsWith("/oadc")
      ) {
        return history.replace("/");
      }
    }
  }, [location.pathname, globalState.profile, history]);

  // useEffect(() => {
  //   if (x < 1) return;

  //   const navigateToProfile = () => {
  //     globalDispatch({ type: NAVIGATE_TO_PROFILE });
  //   };

  //   console.log("🔥🔥", { globalState, history, location });

  //   if (!globalState.profileChangeTriggered) {
  //     if (globalState.userDetails.email?.endsWith(EmailType.WIOCC)) {
  //       switchProfile(ProfileType.CONNECTIVITY);

  //       if (!globalState.alreadyNavigatedToProfile) {
  //         history.replace("/");
  //         navigateToProfile();
  //       }

  //       return;
  //     } else if (globalState.userDetails.email?.endsWith(EmailType.OADC)) {
  //       switchProfile(ProfileType.OADC);

  //       if (!globalState.alreadyNavigatedToProfile) {
  //         history.replace("/oadc");
  //       }

  //       return;
  //     } else if (globalState.userDetails.email?.endsWith(EmailType.OAMS)) {
  //       switchProfile(ProfileType.OAMS);

  //       if (!globalState.alreadyNavigatedToProfile) {
  //         history.replace("/oams");
  //         navigateToProfile();
  //       }

  //       return;
  //     }
  //   }

  //   if (globalState.profile === ProfileType.OAMS) {
  //     if (location.pathname.startsWith("/oams")) {
  //       return;
  //     }

  //     if (!globalState.alreadyNavigatedToProfile) {
  //       history.replace("/oams");
  //       navigateToProfile();
  //     }
  //   } else if (globalState.profile === ProfileType.OADC) {
  //     if (location.pathname.startsWith("/oadc")) {
  //       return;
  //     }

  //     if (!globalState.alreadyNavigatedToProfile) {
  //       history.replace("/oadc");
  //       navigateToProfile();
  //     }
  //   } else if (globalState.profile === ProfileType.CONNECTIVITY) {
  //     if (!globalState.alreadyNavigatedToProfile) {
  //       history.replace("/");
  //       navigateToProfile();
  //     }
  //   }
  // }, [
  //   history,
  //   globalState.profile,
  //   location.pathname,
  //   globalState.profileChangeTriggered,
  //   globalState.alreadyNavigatedToProfile,
  //   globalState.userDetails.email,
  //   switchProfile,
  //   globalDispatch,
  // ]);

  return { globalState, globalDispatch };
};

export default useNavigator;
