import React from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../index.css";

const DotsLoader = ({
  float,
  height = 12,
  width = 12,
  message = "Loading...Please wait",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontSize: "1em",
          fontWeight: "bolder",
          color: "#087830",
          textAlign: "center",
          marginRight: "1.3em",
        }}
      >
        {message}
      </span>
      <ThreeDots
        color="#1e7e34"
        height={height}
        width={width}
        style={{ float }}
      />
    </div>
  );
};

export default DotsLoader;
